const diceGroupTypes = {
  oneOrFive: 'oneOrFive',
  threeOfAKind: 'threeOfAKind',
  sixOfAKind: 'sixOfAKind',
  threePairs: 'threePairs',
  run: 'run',
};

const TURN_STATES = {
  HASNT_STARTED: 'HASNT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  BLAPPED: 'BLAPPED',
  STICKED: 'STICKED',
  STICKED_AND_OVERSHOT: 'STICKED_AND_OVERSHOT',
  STICKED_AND_WON: 'STICKED_AND_WON',
  SKIPPED_AS_PLAYER_PREVIOUSLY_LEFT_GAME:
    'SKIPPED_AS_PLAYER_PREVIOUSLY_LEFT_GAME',
  PLAYER_QUIT_GAME_DURING_THEIR_TURN: 'PLAYER_QUIT_GAME_DURING_THEIR_TURN',
};

const ROLL_TYPES = {
  INITIAL: 'INITIAL',
  REROLL: {
    FULL: 'REROLL.FULL',
    SUBSET: 'REROLL.SUBSET',
  },
  TWO_THROWS_TO_DOUBLE_IT: {
    FIRST: 'TWO_THROWS_TO_DOUBLE_IT.FIRST',
    SECOND: 'TWO_THROWS_TO_DOUBLE_IT.SECOND',
  },
};

const ROLL_STATES = {
  BLAPPED: 'BLAPPED',
  BANKING_DICE: 'BANKING_DICE',
  FINISHED_THEN_ROLLED_AGAIN: 'FINISHED_THEN_ROLLED_AGAIN',
  STICKED: 'STICKED',
  STICKED_AND_OVERSHOT: 'STICKED_AND_OVERSHOT',
  STICKED_AND_WON: 'STICKED_AND_WON',
};

const OVERLAYS = {
  SCORES: 'SCORES',
  RULES: 'RULES',
  MENU: 'MENU',
};

export default {
  diceGroupTypes,
  TURN_STATES,
  ROLL_STATES,
  ROLL_TYPES,
  OVERLAYS,
};
