import React from 'react';

// inline svg, so we can change its styles with css (for hover effects)
const TrophyIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    fill="#fff">
    <path
      d="M442.932,39.09L442.076,25h-74.402V0H144.326v25H69.924l-0.856,14.09c-0.207,3.407-4.573,83.955,38.519,129.744
		c17.667,18.772,40.477,29.003,67.874,30.483c17.065,17.778,39.94,29.932,65.539,33.384v98.504h-14.432
		c-29.673,0-53.814,24.141-53.814,53.814v7.683H133.06V512h245.88V392.703h-39.694v-7.683c0-29.673-24.141-53.814-53.815-53.814
		H271v-98.504c25.599-3.452,48.474-15.607,65.539-33.384c27.397-1.48,50.208-11.711,67.874-30.483
		C447.505,123.045,443.139,42.497,442.932,39.09z M129.494,148.337C104.638,121.989,99.789,77.58,99.011,55h45.315v67.038
		c0,14.922,2.949,29.166,8.284,42.191C143.857,160.716,136.191,155.437,129.494,148.337z M348.94,422.703V482H163.06v-59.297
		H348.94z M285.431,361.206c13.133,0,23.816,10.683,23.816,23.814v7.683H202.753v-7.683c0-13.131,10.683-23.814,23.814-23.814
		H285.431z M337.674,122.038c0,45.035-36.639,81.674-81.674,81.674c-45.035,0-81.674-36.639-81.674-81.674V30h163.348V122.038z
			M382.566,148.274c-6.711,7.132-14.396,12.433-23.176,15.956c5.335-13.025,8.284-27.269,8.284-42.192V55h45.342
		C412.274,77.529,407.479,121.801,382.566,148.274z"
    />
  </svg>
);

export default TrophyIcon;
